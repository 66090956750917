<!-- Localized -->
<template>
    <div class="w-full p-5">
        <div class="flex flex-col gap-2">
            <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
                <Loader />
                {{ $t('general.loading') }}
            </div>
            <div v-if="packageData && !isLoading" class="flex flex-col gap-2">
                <FormulateForm class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <div class="form flex w-full flex-col flex-1">
                            <FormulateInput :disabled="!checkPermission('package.edit') || this.$route.params.action == 'view'" class="w-full" :placeholder="$t('components.tenant.tenant_packages.subject_link_valid_duration_days')" :label="$t('components.tenant.tenant_packages.subject_link_valid_duration_days')" type="number" validation="number|min:1,number" v-model="packageData.candidate_form_link_valid_duration" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput :disabled="!checkPermission('package.edit') || this.$route.params.action == 'view'" v-if="ifCheckisReference > 0" class="w-full" :label="$t('components.tenant.tenant_packages.referee_link_valid_duration_days')" :placeholder="$t('components.tenant.tenant_packages.referee_link_valid_duration_days')" type="number" validation="number|min:1,number" v-model="packageData.reference_form_link_valid_duration" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput :disabled="!checkPermission('package.edit') || this.$route.params.action == 'view'" class="w-full" :label="$t('components.tenant.tenant_packages.additional_form_link_valid_duration_days')" :placeholder="$t('components.tenant.tenant_packages.additional_form_link_valid_duration_days')" type="number" validation="number|min:1,number" v-model="packageData.additional_form_link_valid_duration" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="card-actions justify-end" v-allow="'package.edit'" v-if="this.$route.params.action !== 'view'">
                            <Button :text="$t('actions.update')" type="primary" :disabled="this.$route.params.action == 'view'" @click="updateTat()" />
                        </div>
                    </div>
                </FormulateForm>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import axios from "@/axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getPackagesCheck } from "@/modules/checks/services.js";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "link-setting",
    components: {
        Loader,
        Button,
    },
    data: () => ({
        packageData: null,
        isLoading: false,
        checksLists: [],
        package_id: null,
    }),
    async mounted() {
        await this.fetchScreeningData();
        this.package_id = this.$route.params.packageName;
        const { checks } = await getPackagesCheck(this.package_id);
        this.checksLists = checks;
    },
    computed: {
        ...mapGetters(["getPackage"]),
        ifCheckisReference(){
            return this.checksLists.filter(el => el.category.toLowerCase() === 'reference').length
        }
    },

    methods: {
        checkPermission,
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
            this.isLoading = true;
            await this.fetchPackageData(this.$route.params.packageName);
            this.packageData = this.getPackage;
            this.isLoading = false;
        },
        async updateTat() {
            if(this.$route.params?.action == 'view'){
              return
            }
            const url = `/screening-package/${this.packageData.id}`;
            const payload = {
                candidate_form_link_valid_duration: parseInt(this.packageData.candidate_form_link_valid_duration),
                reference_form_link_valid_duration: parseInt(this.packageData.reference_form_link_valid_duration),
                additional_form_link_valid_duration: parseInt(this.packageData.additional_form_link_valid_duration),
            };
            try {
                await axios.put(url, payload);
                this.SET_PACKAGE = { ...this.getPackage, candidate_form_link_valid_duration: this.packageData.candidate_form_link_valid_duration, reference_form_link_valid_duration: this.packageData.reference_form_link_valid_duration, additional_form_link_valid_duration: this.packageData.additional_form_link_valid_duration };
                this.$toast.success(this.$t('components.tenant.tenant_packages.package_update_success'));
            } catch (error) {
                console.log('error :>> updateTat', error);
                this.$toast.error(error?.response?.data?.detail?.[0]?.msg || this.$t('components.tenant.tenant_packages.package_update_fail'));
            }
        },
    },
};
</script>

<style scoped></style>
